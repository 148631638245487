import Marquee from "react-fast-marquee";
import useStore, { STATES } from "../gameStore";
import { QuestionSet } from "../interfaces";
import "./GameMarquee.css";

const GameMarquee = () => {
  const {
    renderedGame: game,
    gameState,
    questionSets,
    error,
    previewQuestionSet,
  } = useStore();
  const questionSet: QuestionSet | undefined = questionSets?.find(
    (set) => game?.question_sets?.indexOf(set.id) !== -1
  );
  const gapString = "\xa0\xa0\xa0\xa0";
  let message = "waiting for players...";
  let showSet = true;
  let backgroundColor = "#000";
  let className = "";
  switch (gameState) {
    case STATES.PLAYER_SCREEN_GENERATION:
    case STATES.PLAYER_SCREEN_NAME:
      message = "Join the game!";
      break;
    case STATES.PLAYER_SCREEN_JOIN:
      message = "Waiting for players...";
      break;
    case STATES.PLAYER_SCREEN_PLAY_QUESTION:
    case STATES.HOST_QUESTION:
      message = `${game!.turn?.team?.name}, it's your turn!`;
      break;
    case STATES.PLAYER_SCREEN_PLAY_CAPTAIN:
      message = `Trivia judge`;
      break;
    case STATES.PLAYER_SCREEN_PLAY_WAIT:
      message = "It's not your turn";
      break;
    case STATES.PLAYER_SCREEN_PLAY_END:
      message = `${
        game!.teams!.find((o) => o.id === game!.winner)!.name
      } WINS!`;
      className = "game-over";
      break;
    case STATES.HOST_START_SCREEN:
      //message = `NEW!${gapString}FOOD FIGHT!`;
      message = "Learning Quiz";
      showSet = false;
      break;
    case STATES.HOST_GAME_MENU:
      message = "Learning Quiz";
      showSet = false;
      break;
    case STATES.HOST_JOIN_SCREEN:
      message = "Waiting for players";
      break;
    case STATES.HOST_INSTRUCTIONS:
    case STATES.PLAYER_INSTRUCTIONS:
    case STATES.HOST_SETUP_INSTRUCTIONS:
      message = "How to play";
      break;
    case STATES.HOST_PICK:
    case STATES.PLAYER_SCREEN_PLAY_GENERATION:
      message = `${game!.turn?.team?.name}, pick a card`;
      break;
    case STATES.HOST_GAME_OVER:
      message = game!
        .teams!.map(
          (team) =>
            `${team.name} - ${team.points}/15 points, ${team.generation_points}/5 generations\xa0\xa0\xa0\xa0`
        )
        .join("");
      className = "game-over";
      break;
    case STATES.ERROR:
      message = error ? `Error: ${error}` : "Loading";
      break;
    default:
      break;
  }
  // if (questionSet) {
  //   const slide = packSlides.find((slide) => slide.name === questionSet.name);
  //   if (slide && showSet) {
  //     message = `${message}${gapString}${slide.title}`;
  //     backgroundColor = slide.color || backgroundColor;
  //   } else if (
  //     previewQuestionSet !== null &&
  //     packSlides[previewQuestionSet]?.title
  //   ) {
  //     const previewSlide = packSlides[previewQuestionSet];
  //     message = `${message}${gapString}${previewSlide?.title}`;
  //     backgroundColor = previewSlide?.color || backgroundColor;
  //   }
  // }

  return (
    <Marquee
      className={`GameMarquee-marquee ${className}`}
      style={{ backgroundColor, color: "#fff" }}
      gradient={false}
    >
      {Array(50).fill(`${message}${gapString}`).join("")}
    </Marquee>
  );
};

export default GameMarquee;
