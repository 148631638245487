import { Howl } from "howler";
import correct_answer from "./audio/correct_answer.aac";
import music1File from "./audio/dog_goes_outside.mp3";
import hint_reveal from "./audio/hint_reveal.aac";
import join_game from "./audio/join_game.aac";
import music2File from "./audio/last_light.mp3";
import music3File from "./audio/nerd_power_media_theme.mp3";
import score_meter from "./audio/score_meter.aac";
import score_point from "./audio/score_point.aac";
import start_game from "./audio/start_game.aac";
import switch_teams from "./audio/switch_teams.aac";
import tutorial1File from "./audio/tutorial_1.mp3";
import tutorial2File from "./audio/tutorial_2.mp3";
import tutorial3File from "./audio/tutorial_3.mp3";
import tutorial4File from "./audio/tutorial_4.mp3";
import wrong_answer from "./audio/wrong_answer.aac";

export const music1 = new Howl({
  src: [music1File],
});
export const music2 = new Howl({
  src: [music2File],
});
export const music3 = new Howl({
  src: [music3File],
});

export const soundEffects = {
  correctAnswer: new Howl({
    src: [correct_answer],
  }),
  wrongAnswer: new Howl({
    src: [wrong_answer],
  }),
  joinGame: new Howl({
    src: [join_game],
  }),
  switchTeams: new Howl({
    src: [switch_teams],
  }),
  startGame: new Howl({
    src: [start_game],
  }),
  hintReveal: new Howl({
    src: [hint_reveal],
  }),
  scorePoint: new Howl({
    src: [score_point],
  }),
  scoreMeter: new Howl({
    src: [score_meter],
  }),
};

export const tutorialSounds = [
  tutorial1File,
  tutorial2File,
  tutorial3File,
  tutorial4File,
].map((file) => new Howl({ src: [file] }));

export const stopAllTutorial = () => {
  tutorialSounds.forEach((t) => t.stop());
};

export const stopAllMusic = () => {
  music1.stop();
  music2.stop();
  music3.stop();
};

export const stopAllSoundEffects = () => {
  Object.values(soundEffects).forEach((s) => s.stop());
};

export const stopAllSound = () => {
  stopAllTutorial();
  stopAllMusic();
  stopAllSoundEffects();
};
