import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import useStore from "../gameStore";
import check from "../images/checkblank.svg";
import checkBoomer from "../images/checkboomer.svg";
import checkGenX from "../images/checkgenx.svg";
import checkGenZ from "../images/checkgenz.svg";
import checkGreatest from "../images/checkgreatest.svg";
import checkMillennial from "../images/checkmillennial.svg";
import { Team } from "../interfaces";
import { containerVariants, itemVariants } from "../motionVariants";
import GenTag from "./GenTag";
import "./ScoreCard.css";

const checkImages = {
  1922: checkGreatest,
  1946: checkBoomer,
  1965: checkGenX,
  1981: checkMillennial,
  1997: checkGenZ,
};
const maxScore = 20;
const ScoreCard = ({ team, className }: { team: Team; className?: string }) => {
  const { renderedGame: game } = useStore();
  const [score, setScore] = useState(0);
  const [active, setActive] = useState(false);
  // animate score up from zero each time it changes or is rendered
  const slideScore = () => {
    const interval = setInterval(() => {
      setScore((prev) => {
        if (prev < Math.min(team.points, maxScore)) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 100);
    return () => clearInterval(interval);
  };
  useEffect(slideScore, [team.points]);
  useEffect(() => {
    setActive(game?.turn?.team.id === team.id);
  }, [game?.turn?.team.id, team.id]);

  return (
    <div
      className={`ScoreCard 
        ${className || ""}
        ${active ? "active" : ""}`}
    >
      <div className="ScoreCard-team-info">
        <h2>{team.name}</h2>
        <ul className="ScoreCard-team">
          {team.players?.map((player) => (
            <GenTag as="li" key={player.id} player={player} small={true} />
          ))}
        </ul>
      </div>
      <div className="ScoreCard-score">
        <motion.div
          className="ScoreCard-gen-checks"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          {Object.entries(checkImages).map(([generation, image]) => (
            <motion.img
              variants={itemVariants}
              transition={{ type: "spring" }}
              key={`${generation}-${team.id}`}
              src={
                team.points_generations.includes(parseInt(generation))
                  ? image
                  : check
              }
              alt={`Check for ${generation}`}
              className="ScoreCard-check"
            />
          ))}
        </motion.div>
        <div className="ScoreCard-scores-team-points">
          <div
            className="ScoreCard-scores-team-points-fill"
            style={{
              width: `${(score / maxScore) * 100}%`,
            }}
          ></div>
          <p className="ScoreCard-scores-team-points-text">
            {score}/{maxScore} Points
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
