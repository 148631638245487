import { useEffect, useRef } from "react";
import useStore from "../../gameStore";
import { QuestionSet } from "../../interfaces";
import "./HostExpansionCard.css";

// TODO: this should be defined one place for here and GameMenu
export type PackStatus = "free" | "owned" | "coming soon" | "for sale";
export type Pack = {
  id: string;
  name: string;
  image: string;
  title: string;
  status: PackStatus;
  invert?: boolean;
  price?: string;
  description?: string;
  color?: string;
  isNew?: boolean;
};
const HostExpansionCard = ({
  pack,
  onClick,
}: {
  pack: Pack;
  onClick?: () => void;
}) => {
  const { id, image, title, status, invert, price, isNew } = pack;
  const { setShowJoinGame, setShowSetupInstructions, questionSets } =
    useStore();
  const cardRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // if this is the first card in its parent, select it
    // if (cardRef.current?.parentElement?.firstChild === cardRef.current) {
    if (isNew) {
      setSelected();
    }

    // update data from store
    const questionSet: QuestionSet | undefined = questionSets?.find(
      (set) => set.name === pack.name
    );
    if (questionSet) {
      pack.price = questionSet.price
        ? `\$${questionSet.price?.toString()}`
        : undefined;
      pack.status = questionSet.price ? "owned" : "free";
      pack.id = questionSet.id;
    }
  }, []);
  const setSelected = () => {
    const cards = document.querySelectorAll(".HostExpansionCard-pack");
    cards.forEach((card) => card.classList.remove("selected"));
    cardRef.current?.classList.add("selected");
    cardRef.current?.focus();
  };

  return (
    <button
      className={`HostExpansionCard-pack ${
        status === "coming soon" ? "disabled" : ""
      }`}
      onFocus={() => {
        cardRef.current?.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
        setSelected();
      }}
      onMouseEnter={setSelected}
      onClick={() => {
        if (status !== "coming soon" && onClick) onClick();
      }}
      ref={cardRef}
    >
      <img src={image} alt={title} />
      <h2 style={{ color: invert ? "#fff" : "#000" }}>{title}</h2>
      {(price || status === "coming soon") && (
        <span className="status">{price || status}</span>
      )}
      {isNew && <span className="status new">NEW</span>}
    </button>
  );
};

export default HostExpansionCard;
