import { useState } from "react";
import useStore, { checkPassword } from "../../gameStore";
import keyMailerImg from "../../images/keymailer.png";
import { setCookie } from "../../utils";
import "./HostPassword.css";

const HostPassword = (props: any) => {
  const [typedPassword, setTypedPassword] = useState("");
  const [message, setMessage] = useState("To continue, enter your key");
  const { setPassword } = useStore();

  const handlePasswordChange = (e: any) => {
    setTypedPassword(e.target.value);
  };

  const submitPassword = (event: any) => {
    if (!checkPassword(typedPassword)) {
      setMessage("Incorrect key");
    } else {
      setCookie("password", typedPassword);
      setPassword(typedPassword);
      document.location = "/";
    }
  };

  return (
    <div className="HostPassword">
      <h1>Learning Quiz</h1>
      <div className="HostPassword-input">
        <p>{message}</p>
        <input
          type="text"
          name="password"
          placeholder="Key"
          onChange={handlePasswordChange}
        />
        <button className="normal-button" onClick={submitPassword}>
          Login
        </button>
        <p>Don't have a key?</p>
        <a
          href="https://www.keymailer.co/g/games/163693"
          target="_blank"
          rel="noreferrer"
        >
          {/* NOTE: norefferer is because the linter, feel free to remove it */}
          <button
            className="keymailer-button"
            aria-label="Request via KeyMailer"
          >
            <img src={keyMailerImg} alt="Keymailer" />
          </button>
        </a>
      </div>
    </div>
  );
};
export default HostPassword;
