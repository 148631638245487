import { useEffect } from "react";
import useStore from "../../gameStore";
import packClassic from "../../images/pack_classic.png";
import { setCookie } from "../../utils";
import Loading from "../Loading";
import SecondaryButton from "../SecondaryButton";
import HostExpansionCard, { Pack } from "./HostExpansionCard";
import HostExpansionMenu from "./HostExpansionMenu";
import HostStartHeader from "./HostStartHeader";
import "./HostStartScreen.css";

// TODO: this should be defined one place for here and GameMenu
export const packSlides: Pack[] = [
  {
    id: "",
    name: "Premium",
    image: packClassic,
    title: "Classic",
    status: "free",
    description: "The original pack",
  },
];

const imgPositions = [
  { top: 20, left: 90 },
  { top: 30, left: 70 },
  { top: 50, left: 60 },
  { top: 75, left: 30 },
  { top: 85, left: 40 },
  { top: 90, left: 10 },
];

const HostStartScreen = () => {
  const {
    questionSets,
    previewQuestionSet,
    setPreviewQuestionSet,
    updateGame,
    setShowSetupInstructions,
    setShowJoinGame,
    setShowMenu,
    liveGame,
  } = useStore();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("set");
    if (id) {
      setCookie("question_set", id);
      updateGame(liveGame!.id!, {
        question_sets: [id],
      });
      setShowSetupInstructions(true);
      setShowJoinGame(true);
      setShowMenu(false);
    }
  }, []);
  if (!questionSets) {
    return <Loading />;
  } else {
    return (
      <>
        {previewQuestionSet === null ? (
          <>
            <HostStartHeader />
            <div className="StartScreen-packs">
              {packSlides.map((pack, i) => (
                <HostExpansionCard
                  key={i}
                  pack={pack}
                  onClick={() => setPreviewQuestionSet(i)}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <HostExpansionMenu pack={packSlides[previewQuestionSet]}>
              <SecondaryButton onClick={() => setPreviewQuestionSet(null)}>
                Close
              </SecondaryButton>
            </HostExpansionMenu>
          </>
        )}
      </>
    );
  }
};

export default HostStartScreen;
