import { useEffect, useState } from "react";
import useStore from "../../gameStore";
import { music3, soundEffects, stopAllSound } from "../../sound";
import GameCard from "../GameCard";
import GenTag from "../GenTag";
import QuestionSection from "../QuestionSection";
import ResultSticker from "../ResultSticker";
import style from "../SharedStyles.module.css";
import TimedNotification from "../TimedNotification";
import "./HostQuestion.css";

const HostQuestion = () => {
  const { renderedGame: game, muteMusic, muteSounds } = useStore();
  const [question, setQuestion] = useState(game!.turn!.question);
  const [showResult, setShowResult] = useState(false);
  const [usedHint, setUsedHint] = useState(game?.turn?.used_hint || false);

  useEffect(() => {
    stopAllSound();
    if (!muteMusic) {
      music3.play();
    }
    return stopAllSound;
  }, [muteMusic]);

  useEffect(() => {
    stopAllSound();
    if (!muteSounds && showResult) {
      if (game?.last_turn?.correct) {
        soundEffects.correctAnswer.play();
      } else {
        soundEffects.wrongAnswer.play();
      }
    }
    return stopAllSound;
  }, [showResult, muteSounds]);

  useEffect(() => {
    if (game?.turn?.used_hint && !usedHint) {
      soundEffects.hintReveal.play();
    }
    setUsedHint(game?.turn?.used_hint || false);
  }, [game?.turn?.used_hint]);

  useEffect(() => {
    setQuestion(game!.turn!.question);
  }, [game!.turn!.question]);

  useEffect(() => {
    setShowResult(game?.last_turn?.question?.id === game?.turn?.question?.id);
  }, [game?.last_turn?.question?.id, game?.turn?.question?.id]);

  return (
    <>
      <div className="HostQuestion">
        <div
          className={`HostQuestion-display 
        ${style[`generation-${question!.generation}`]}`}
        >
          <GameCard
            initial="hidden"
            animate="show"
            className="HostQuestion-card"
            question={question!}
          />
          {/* <div className="HostQuestion-logo">Learning Quiz</div> */}
          {showResult && <ResultSticker />}
        </div>
        <div className="HostQuestion-question">
          <QuestionSection title="Question" body={question!.question!} />
          {(game?.turn?.used_hint || showResult) && (
            <QuestionSection title="Hint" body={question?.hint!} />
          )}
          {showResult && (
            <QuestionSection
              title="Answer"
              body={game?.last_turn?.question?.answer}
            />
          )}
          <div className="HostQuestion-team-info">
            <h3>{game?.turn?.team.name}</h3>
            <ul className="HostQuestion-team">
              {game?.turn?.team.players?.map((player) => (
                <GenTag as="li" key={player.id} player={player} small={true} />
              ))}
            </ul>
          </div>
        </div>
      </div>

      <TimedNotification
        repeat={true}
        childrenOptions={[
          <p>
            <b>{game?.turn?.team.name}</b> tell{" "}
            {game?.teams?.find((t) => t.id !== game?.turn?.team.id)?.name} your
            <br /> final answer or take a hint.
          </p>,
          <p>
            <b>
              {game?.teams?.find((t) => t.id !== game?.turn?.team.id)?.name}
            </b>{" "}
            use your phone to
            <br /> select if {game?.turn?.team.name} was right.
          </p>,
        ]}
      />
    </>
  );
};

export default HostQuestion;
