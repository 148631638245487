import useStore from "../../gameStore";
import inPersonImg from "../../images/instructions_livingroom.jpg";
import virtaulImg from "../../images/instructions_virtual.jpg";
import MainButton from "../MainButton";
import "./HostSetupInstructions.css";

const HostSetupInstructions = () => {
  const { setShowSetupInstructions } = useStore();
  const clickDone = () => {
    setShowSetupInstructions(false);
  };
  return (
    <div className="HostSetupInstructions">
      <h3>
        To play you've gotta be able to talk to each <br />
        other... so make sure you’re either:
      </h3>
      <div className="HostSetupInstructions-instructions">
        <div className="HostSetupInstructions-option">
          <img src={inPersonImg} alt="Play in person" />
          <p>Together in person</p>
        </div>
        <div className="HostSetupInstructions-option">
          <img src={virtaulImg} alt="Play on a video call" />
          <p>On a video call with audio</p>
        </div>
      </div>
      <MainButton
        autoFocus
        className="HostSetupInstructions-done-button"
        onClick={clickDone}
      >
        Got it
      </MainButton>
    </div>
  );
};
export default HostSetupInstructions;
