import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getFirestore } from "firebase/firestore";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FirestoreProvider, useFirebaseApp } from "reactfire";
import HostPassword from "./components/host/HostPassword";
import HostScreen from "./components/HostScreen";
import NewScreen from "./components/NewScreen";
import PlayerScreen from "./components/PlayerScreen";
import { thirdPartyInit } from "./thirdParty/thirdParty";
import { setCookie } from "./utils";

function App() {
  if (process.env.REACT_APP_SENTRY_DSN !== "") {
    // Sentry init
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    });
  }

  // GA init
  ReactGA.initialize(process.env.REACT_APP_GA_ID!);

  // Firestore init
  const firestoreInstance = getFirestore(useFirebaseApp());

  // Get game params from query string
  const queryParams = new URLSearchParams(window.location.search);
  const deviceId = queryParams.get("dev");
  const questionSet = queryParams.get("set");
  const app = queryParams.get("app");
  if (app) {
    // Application entry (e.g. "comcast")
    setCookie("app", app);
  }
  if (deviceId) {
    // Unique ID for the host device
    setCookie("device_id", deviceId);
  }
  if (questionSet) {
    // ID of a question set to force load
    setCookie("question_set", questionSet, null);
  }

  thirdPartyInit();
  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/:code/:player_id" element={<PlayerScreen />} />
          <Route path="/:code" element={<PlayerScreen />} />
          <Route path="/host/:code" element={<HostScreen />} />
          <Route path="/" element={<NewScreen />} />
          <Route path="/key" element={<HostPassword />} />
        </Routes>
      </BrowserRouter>
    </FirestoreProvider>
  );
}

export default App;
