import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import useStore from "../../gameStore";
import { soundEffects } from "../../sound";
import BonusSticker from "../BonusSticker";
import "./HostBonusStickers.css";

const HostBonusStickers = (props: any) => {
  const [show, setShow] = useState(true);
  const { renderedGame: game, muteSounds } = useStore();
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!muteSounds && show) {
      setTimeout(() => {
        soundEffects.scorePoint.play();
      }, 250);
      if (!game?.last_turn?.used_hint) {
        setTimeout(() => {
          soundEffects.scorePoint.play();
        }, 500);
      }
    }
  }, [show]);
  return (
    <motion.div className="HostBonusStickers">
      <AnimatePresence>
        {show && (
          <motion.div
            key="HostBonusSticker"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="HostBonusStickers-background"
          />
        )}
        {show && !game?.last_turn?.used_hint && (
          <BonusSticker
            key="HostBonusSticker-no-hint"
            className="HostBonusStickers-sticker HostBonusSticker-no-hint"
            delay={0.5}
          >
            No hint!
          </BonusSticker>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
export default HostBonusStickers;
